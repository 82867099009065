import type { LocationType } from '@components/Pageheader/Search/Item/Dropdown/Filter/Distance/models';
import type { RawWidgetConfigFragment } from '@gql/fragments/__generated/RawWidgetConfig';
import type { BaseLocation, Location } from '@models/BaseLocation';
import type { Nullable } from '@models/CustomUtilityTypes';
import { TimeOfDay } from '@models/TimeOfDay';

interface BaseSearchModel {
  search: string[];
  categories: number[];
  criteria: number[];
  locationId: number | undefined;
  locationType: LocationType | undefined;
  locationName: string | undefined;

  latitude: number | undefined;
  longitude: number | undefined;
  radius: number | undefined;
}

interface TimeOfDayModel {
  dateFrom: string | undefined;
  dateTo: string | undefined;
  daytime: TimeOfDay[];
}

export interface EventSearchModel extends BaseSearchModel, TimeOfDayModel {
  onlyFree: true | undefined;
  hasSingleEvent: true | undefined;
}

export interface PoiSearchModel extends BaseSearchModel, TimeOfDayModel {}

export interface TourSearchModel extends BaseSearchModel {
  duration: [number, number] | undefined;
  length: [number, number] | undefined;
  difficulties: number[];
}

export type SearchModel = EventSearchModel & PoiSearchModel & TourSearchModel;

const DEFAULT_STATE: SearchModel = {
  search: [],
  dateFrom: undefined,
  dateTo: undefined,
  daytime: [],
  onlyFree: undefined,
  hasSingleEvent: undefined,
  categories: [],
  criteria: [],
  duration: undefined,
  length: undefined,
  difficulties: [],
  locationId: undefined,
  locationType: undefined,
  locationName: undefined,
  latitude: undefined,
  longitude: undefined,
  radius: undefined,
};

const getLocationNameFromPlatform = async (
  locationId?: number,
  locationType?: LocationType
): Promise<string | null> => {
  const locations = await fetchAutosuggestLocations().then(
    (res) => res.locations
  );
  if (isEmpty(locations)) {
    return '';
  }
  let locationArray: Location[] | BaseLocation[] = [];
  switch (locationType) {
    case 'Location':
      locationArray = (locations.value?.locations || []).filter(
        (loc) => loc._entityType !== undefined
      ) as Location[];
      break;
    case 'Region':
      locationArray = (locations.value?.regions || []).filter(
        (loc) => loc._entityType !== undefined
      ) as BaseLocation[];
      break;
    case 'AddressPoiGroup':
      locationArray = (locations.value?.addressPoiGroups || []).filter(
        (loc) => loc._entityType !== undefined
      ) as BaseLocation[];
      break;
    default:
      locationArray = [];
  }

  const location = locationArray.find(
    (loc) => loc.id === locationId && loc._entityType === locationType
  );

  return location?.i18nName || location?._entityType === 'Location'
    ? (location as Location).name
    : null;
};

const getLocationNameFromMTK = async (
  latitude: number,
  longitude: number
): Promise<string | null> => {
  try {
    return await reverseGeoCodeCoordinates({ latitude, longitude });
  } catch {
    return null;
  }
};

const getLocationName = async (
  locationId: number | undefined,
  locationType: LocationType | undefined,
  latitude: number | undefined,
  longitude: number | undefined
): Promise<string> => {
  if (latitude && longitude) {
    return (await getLocationNameFromMTK(latitude, longitude)) || '';
  } else if (locationId && locationType) {
    return (await getLocationNameFromPlatform(locationId, locationType)) || '';
  } else return '';
};

const VALID_TIMES_OF_DAY = [
  TimeOfDay.MORNING,
  TimeOfDay.AFTERNOON,
  TimeOfDay.EVENING,
];

export const useSearchStore = defineStore('searchStore', () => {
  const router = useRouter();
  const state = reactive<SearchModel>({ ...DEFAULT_STATE });

  // build user filter computeds here, as they are then a singleton through the application
  const eventUserFilter = buildEventUserFilter(state);
  const poiUserFilter = buildPoiUserFilter(state);
  const tourUserFilter = buildTourUserFilter(state);

  const pushStateToRoute = (
    widgetConfig?: MaybeRef<Nullable<RawWidgetConfigFragment>>
  ): void => {
    const { locationName, ...stateToPush } = state;
    const queryToPush = filterObject({
      ...stateToPush,
      search: state.search.join(','),
      categories: state.categories.join(','),
      criteria: state.criteria.join(','),
      duration: isDefined(state.duration)
        ? state.duration.join(',')
        : undefined,
      length: isDefined(state.length) ? state.length.join(',') : undefined,
      difficulties: state.difficulties.join(','),
      daytime: state.daytime.join(','),
      hasSingleEvent: state.hasSingleEvent ? 'true' : undefined,
      onlyFree: state.onlyFree ? 'true' : undefined,
    });

    router.push({
      query: queryToPush,
      path: widgetConfig ? toValue(buildLinkToListPage(widgetConfig)) : '#',
    });
  };

  const readStateFromRoute = async (): Promise<SearchModel> => {
    const { query } = useRoute();

    const dateFrom = query.dateFrom;
    const dateTo = query.dateTo || query.dateFrom;

    if (dateFrom) {
      state.dateFrom = dateFrom as string;
      state.dateTo = dateTo as string;
    }
    if (query.daytime) {
      state.daytime =
        ((query.daytime as string)?.split(',') as TimeOfDay[])
          .filter((val) => VALID_TIMES_OF_DAY.includes(val))
          .sort(
            (a, b) =>
              VALID_TIMES_OF_DAY.indexOf(a) - VALID_TIMES_OF_DAY.indexOf(b)
          ) ?? [];
    }
    if (query.search) {
      state.search = (query.search as string)?.split(',') ?? [];
    }
    if (query.onlyFree) {
      state.onlyFree = query.onlyFree === 'true' ? true : undefined;
    }
    if (query.hasSingleEvent) {
      state.hasSingleEvent = query.hasSingleEvent === 'true' ? true : undefined;
    }
    if (query.categories) {
      state.categories =
        (query.categories as string)
          ?.split(',')
          .filter((val) => isConvertibleToNumber(val))
          .map(Number) ?? [];
    }
    if (query.criteria) {
      state.criteria =
        (query.criteria as string)
          ?.split(',')
          .filter((val) => isConvertibleToNumber(val))
          .map(Number) ?? [];
    }
    if (query.latitude) {
      state.latitude = isConvertibleToNumber(query.latitude)
        ? Number(query.latitude as string)
        : undefined;
    }
    if (query.longitude) {
      state.longitude = isConvertibleToNumber(query.longitude)
        ? Number(query.longitude as string)
        : undefined;
    }
    if (query.radius) {
      state.radius = isNaN(Number(query.radius as string))
        ? undefined
        : Number(query.radius as string);
    }
    if (query.duration) {
      const duration = (query.duration as string)
        ?.replace(/\s+/g, '')
        .split(',')
        .slice(0, 1)
        .every((part) => !isEmpty(part) && !isNaN(Number(part)))
        ? (query.duration as string)
            .replace(/\s+/g, '')
            .split(',')
            .slice(0, 2)
            .map(Number)
        : undefined;
      state.duration =
        duration?.length === 2 ? (duration as [number, number]) : undefined;
    }
    if (query.length) {
      const length = (query.length as string)
        ?.replace(/\s+/g, '')
        .split(',')
        .slice(0, 1)
        .every((part) => !isEmpty(part) && isConvertibleToNumber(part))
        ? (query.length as string)
            ?.replace(/\s+/g, '')
            .split(',')
            .slice(0, 2)
            .map(Number)
        : undefined;
      state.length =
        length?.length === 2 ? (length as [number, number]) : undefined;
    }
    if (query.difficulties) {
      state.difficulties =
        (query.difficulties as string)?.split(',').map(Number) ?? [];
    }
    if (query.locationType && query.locationId) {
      state.locationId = isConvertibleToNumber(query.locationId)
        ? Number(query.locationId as string)
        : undefined;
      state.locationType = query.locationType as LocationType;
    }
    if (
      (state.locationId && state.locationType) ||
      (state.latitude && state.longitude)
    ) {
      state.locationName = await getLocationName(
        state.locationId,
        state.locationType,
        state.latitude,
        state.longitude
      );
    }

    pushStateToRoute();

    return state;
  };

  const resetState = (): void => {
    Object.assign(state, DEFAULT_STATE);
    pushStateToRoute();
  };

  const removeValue = (
    key: keyof SearchModel | 'date' | 'location',
    value?: number | string
  ): void => {
    if (key === 'date') {
      state.dateFrom = '';
      state.dateTo = '';
      pushStateToRoute();
      return;
    }

    if (key === 'search') {
      state.search = [];
      pushStateToRoute();
      return;
    }

    if (key === 'duration' || key === 'length') {
      state[key] = undefined;
      pushStateToRoute();
      return;
    }

    if (key === 'location') {
      state.locationId = undefined;
      state.locationType = undefined;
      state.locationName = undefined;
      state.latitude = undefined;
      state.longitude = undefined;
      pushStateToRoute();
      return;
    }

    const stateValue = state[key];
    if (Array.isArray(stateValue) && value !== undefined) {
      (state[key] as (string | number)[]) = stateValue.filter(
        (v) => v !== value
      );
    } else if (Array.isArray(stateValue)) {
      (state[key] as (string | number)[]) = [];
    } else {
      (state[key] as undefined) = undefined;
    }

    pushStateToRoute();
  };

  const updateState = (
    newState: Partial<SearchModel>,
    widgetConfig: Nullable<RawWidgetConfigFragment>
  ): void => {
    Object.keys(newState).forEach((key) => {
      const typedKey = key as keyof SearchModel;
      if (
        newState[typedKey] !== undefined ||
        ['onlyFree', 'hasSingleEvent'].includes(typedKey) /* boolean flags */
      ) {
        (state[typedKey] as unknown) = newState[
          typedKey
        ] as SearchModel[typeof typedKey];
      }
    });

    pushStateToRoute(widgetConfig);
  };

  const hasAnyValue = computed((): boolean => {
    return Object.entries(state).some(([_, value]) => !isEmpty(value));
  });

  const fulltextSearchQuery = computed((): string => {
    return state.search.join(' ');
  });

  return {
    // (reactive) state itself
    state,

    // getters
    eventUserFilter,
    poiUserFilter,
    tourUserFilter,
    fulltextSearchQuery,
    hasAnyValue,

    // actions
    pushStateToRoute,
    readStateFromRoute,
    resetState,
    updateState,
    removeValue,
  };
});
