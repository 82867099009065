import type { RawWidgetConfigFragment } from '@gql/fragments/__generated/RawWidgetConfig';
import type { Nullable } from '@models/CustomUtilityTypes';
import { WhlModuleType } from '@models/WhlModuleType';
export default (
  widgetConfig?: MaybeRef<Nullable<RawWidgetConfigFragment>>,
  to?: Nullable<WhlModuleType>
): ComputedRef<string> => {
  return computed(() => {
    const localePath = useLocalePath();
    const whlModuleType = useWhlModuleType();
    const moduleType = to ?? toValue(whlModuleType)!;

    switch (moduleType) {
      case WhlModuleType.Event:
        return localePath(
          widgetConfig
            ? prefixLinkPath('/events/list', widgetConfig)
            : '/events/list'
        );
      case WhlModuleType.Tour:
        return localePath(
          widgetConfig
            ? prefixLinkPath('/tours/list', widgetConfig)
            : '/tours/list'
        );
      case WhlModuleType.Poi:
        return localePath(
          widgetConfig
            ? prefixLinkPath('/pois/list', widgetConfig)
            : '/pois/list'
        );
      default:
        return localePath('/');
    }
  });
};
